<template>
  <div class="box">
    <homes ref="productImage"></homes>
    <div class="content">
      <div class="title_box">
        <i class="el-icon-minus"></i>
        <span class="title">{{
          $route.query.value == 1 ? " 人才信用评价" : "班组信用评价"
        }}</span>
        <i class="el-icon-minus"></i>
      </div>
      <div class="nav-content">
        <!-- <p class="solid"></p> -->
        <!-- <div class="percentage-box">
          <div class="percentage">资料完善度 {{ percentage }}%</div>
        <el-progress :percentage="percentage" :show-text="false"></el-progress>
        </div> -->
        <div class="span">
          <div
            v-if="!isSubmit"
            style="color: red; font-size: 20px; margin-bottom: 15px"
          >
            {{ message }}
            <!-- <el-tooltip class="item" effect="dark" content="如想提前重新再次提交,请购买相关权益后,点击此按钮" placement="top">
              <el-button type="primary" @click="toDeduct">再次提交</el-button>
            </el-tooltip> -->
          </div>
          为了获取准确真实的信用评分，希望您抽出几分钟时间上传您的基本信息和工作信息，提交之后会信用评价分数会在审核通过之后显示。
          <p style="color: #999999;">注:上传图片只能是JPG格式或PNG格式,且图片大小不能超过5MB,上传图片时请检查图片原有格式是否是JPG/PNG格式!</p>
        </div>
        <el-collapse :value="activeNames">
          <el-collapse-item
            v-for="(item, index) in list"
            :key="index"
            :title="item.intro"
            :name="item.id"
          >
            <div v-if="item.datas">
              <column
                v-for="(info, idx) in item.datas"
                :key="idx"
                :fileList="info"
                :isSubmit="isSubmit"
                @columnBtn="columnBtn"
                @columnDel="columnDel"
              ></column>
            </div>
          </el-collapse-item>
        </el-collapse>
        <div class="submit">
          <el-button
            v-if="isSubmit"
            class="submit-btn"
            type="primary"
            @click="dataSubmit"
            >提交</el-button
          >
          <el-button
            v-else-if="subStatus == 1 && !isSubmit"
            class="submit-btn"
            type="primary"
            >审核中</el-button
          >
        </div>
      </div>
    </div>
    <!-- <el-dialog
  title="信息真实性承诺证明"

  width="30%">
  <span>{{ message }}</span>
  <span slot="footer" class="dialog-footer">

    <el-button @click="dialogVisible = false">取 消</el-button>
    <el-button type="primary" @click="submit">确 定</el-button>
  </span>
</el-dialog> -->
    <el-dialog
      width="900px"
      :visible.sync="dialogVisible"
      custom-class="previewDialog"
    >
      <div class="create-info">
        <div class="createHide"><i class="el-icon-close" @click="dialogHide"></i></div>
        <img
          class="create-img"
          src="https://img.js.design/assets/img/63be6fd6f3bf02bae20dbdfe.png#b64f3b71d66222139b8ae95011de8a21"
          alt=""
        />
        <div class="create-head">
          本人承诺真实
          <img class="head-img" src="@/assets/home/logo.png" alt="" />
        </div>
        <div class="create-nav">
          <div class="top">本人承诺所提交的信息真实有效</div>
          <div class="center">虚假承诺平台将降低您的信用评分</div>
          <div class="bottom">注: {{ message }}</div>
        </div>
        <div class="create-btn" @click="submit">提交</div>
        <div class="create-floor">
          <div class="floor-left">承诺真实,更加可信</div>
          <div class="floor-right">
            <img class="floor-img" src="@/assets/home/logo.png" alt="" />
            <div class="floor-title">甄工匠</div>
          </div>
        </div>
      </div>
    </el-dialog>

    <!-- 底部 -->
    <footerss class="footer"></footerss>
  </div>
</template>
<script>
import homes from "@/components/hometitle/hometitle.vue";
import footerss from "@/components/foot/foot.vue";
import column from "@/components/column/column.vue";
import { subtractEquityNum, oneEquityNum } from '@/api/equity/equity.js'
import {
  jobScoringList,
  jobScoringAdd,
  jobScoringDelete,
  jobScoringSave,
} from "@/api/evaluation/evaluation";
export default {
  components: {
    footerss,
    homes,
    column,
  },
  data() {
    return {
      dialogVisible: false,
      list: [],
      percentage: 0,
      subStatus: "",
      isSubmit: false,
      message: "",
      // activeNames: ["4", "3"],
    };
  },
  computed: {
    activeNames() {
      return this.list.map((i) => {
        return i.id;
      });
    },
  },
  created() {
    if (this.$route.query.idCard) {
      this.getList(this.$route.query.idCard);
    }
  },
  methods: {
    toDeduct(){
      let obj = {}
      obj.jobUserId = this.userId
      obj.index = 2,
      obj.userType = 1
      oneEquityNum(obj).then(res => {
        if(res.success) {
          if(res.data == 0) {
            this.$confirm('当前权益次数不足，是否前往充值？', '', {
                confirmButtonText: '充值',
                showCancelButton: false,
                type: 'warning'
              }).then(() => {
                this.$router.push('/personalCenter/memberCenter')
              }).catch(() => {});
          } else {
            this.$confirm('是否扣除您的相关权益，再次提交您的自评信息？', '', {
                confirmButtonText: '是',
                showCancelButton: false,
                type: 'success'
              }).then(() => {
                let obj = {
                  subtractNum: 1,
                  jobUserId: this.userId,
                  index: 2,
                  userType: 1
                }
                subtractEquityNum(obj).then(res => {
                if(res.success) {
                    this.$message.success('扣除成功')
                  } else {
                    this.$message.error('扣除失败，请重试')
                  }
                })
              }).catch(() => {});
          }
        }
      })
    },
    dataSubmit(){
      let info = []
      this.list.map(item=>{
        item.datas.map(items=>{
          if(items.examId && !items.datas){
            info.push(items)
          }else{
          }
        })
      })
      if(info.length > 0){
         this.$message.error(`请完成所有考试后再提交!`);
      }else{
        this.dialogVisible = true
      }
    },
    dialogHide(){
      this.dialogVisible = false
    },
    async getList(val) {
      let params = {
        idCard: val,
        userType: this.$route.query.value,
        type: this.$route.query.value,
        targetId: this.$route.query.userId,
      };
      let res = await jobScoringList(params);
      this.list = res.data.datas;
      this.percentage = Number(res.data.ratio);
      this.isSubmit = res.data.isSubmit;
      this.subStatus = res.data.status;
      this.message = res.data.message;
    },
    columnDel(val) {
      let params = [{ id: val.id }];
      jobScoringDelete(params).then((res) => {
        this.getList(this.$route.query.idCard);
      });
    },
    columnBtn(val) {
      let params = {
        userType: this.$route.query.value,
        targetType: this.$route.query.value,
        code: val.idCard,
        file: "",
        indexId: val.id,
        targetId: this.$route.query.userId,
      };
      if (val.datas.length > 0) {
        params.file = val.datas[val.datas.length - 1].file;
      }
      jobScoringAdd(params).then((res) => {
        this.getList(this.$route.query.idCard);
      });
    },
    submit() {
      let params = {
        code: this.$route.query.idCard,
        userType: this.$route.query.value,
        targetType: this.$route.query.value,
        targetId: this.$route.query.userId,
      };
      jobScoringSave(params).then((res) => {
        this.dialogVisible = false;
        this.getList(this.$route.query.idCard);
      });
    },
  },
};
</script>
<style scoped lang="scss">
.content {
  min-height: 650px;
  width: 100%;
  margin: 0 auto;
  margin-top: 80px;
  background: url("~@/assets/img/tableHead.png") no-repeat;
  background-size: 100%;
  overflow: hidden;
  background-color: #edf6fd;
}
.title_box {
  text-align: center;
  margin: 150px 0 50px 0;
  font-size: 38px;
  color: #029aff;
  .title {
    margin: 0 40px 0;
    padding-bottom: 10px;
  }
}
.nav-content {
  background-color: #fff;
  width: 65%;
  margin: 0 auto;
  box-sizing: border-box;
  padding: 20px;
  border-radius: 3px;
  margin-bottom: 60px;
  .solid {
    background-color: #5aa4f2;
    width: 95%;
    height: 6px;
    margin: 30px auto;
    margin-bottom: 50px;
  }
  .span {
    text-align: center;
    font-size: 14px;
    margin-bottom: 30px;
  }
}
::v-deep .el-collapse {
  border-top: none;
  border-bottom: none;
}
::v-deep .el-collapse-item__header {
  border-bottom: 1px dashed #a6a6a6;
  font-size: 18px;
  font-weight: bold;
}
::v-deep .el-collapse-item__wrap {
  // border-bottom: 1px dashed #A6A6A6;
  border-bottom: none;
}
::v-deep .el-collapse-item__arrow {
  margin: 0 8px 0 20px;
}
.submit {
  margin: 20px 0;
  text-align: center;
  .submit-btn {
    width: 120px;
    font-size: 18px;
  }
}
.percentage-box {
  margin: 30px 0;
  .percentage {
    text-align: center;
    color: #5aa4f2;
    margin-bottom: 5px;
  }
}
::v-deep .el-dialog__header {
  display: none;
  padding: 0 !important;
}
::v-deep .el-dialog {
  background-color: rgba(254, 254, 254, 0);
  box-shadow: none;
}
::v-deep .el-dialog__body {
  padding: 0 !important;
  // background: linear-gradient(180deg, rgba(207, 232, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
  // background-color: rgba(207, 232, 255, 0.6);
  background-color: #fff;
  background-image: url("~@/assets/jobInformation/creditEval.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border-radius: 25px;
}
.create-info {
  box-sizing: border-box;
  padding: 20px;
  height: 331px;
  position: relative;
  .createHide {
    text-align: right;
    font-size: 24px;
  }
  .create-img {
    width: 227px;
    height: 241px;
    position: absolute;
    top: -15%;
    left: -2%;
  }
  .create-head {
    text-align: center;
    position: relative;
    font-size: 24px;
    color: #006aff;
    font-weight: bold;
    margin-bottom: 6%;
    .head-img {
      width: 70px;
      height: 75px;
      position: absolute;
      opacity: 0.2;
      left: 46%;
      top: -33%;
    }
  }
  .create-nav {
    text-align: center;
    font-size: 18px;
    color: #666666;
    line-height: 33px;
    margin-bottom: 20px;
  }
  .create-btn {
    width: 180px;
    height: 48px;
    line-height: 48px;
    background: linear-gradient(90deg, #8abff8 0%, #6c69f5 100%);
    color: #fff;
    font-size: 18px;
    text-align: center;
     cursor:pointer;
    border-radius: 3px;
    margin: 0 auto;
  }
  .create-floor {
    display: flex;
    justify-content: space-between;
    color: #999999;
    font-size: 14px;
    opacity: 0.4;
    .floor-right {
      display: flex;
      align-items: center;
      .floor-img {
        width: 17px;
        height: 18px;
        margin-right: 10px;
      }
      .floor-title {
        color: #029aff;
        font-weight: bold;
        letter-spacing: 4px;
        font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
      }
    }
  }
}
</style>
