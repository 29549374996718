<template>
  <div>
    <div class="column-box">
      <div class="column">
        <div class="leftInfo">
          <div class="title">
            <!-- <h4>{{ fileList.dataScopeType + 1 }}</h4> -->
            {{ fileList.intro }}
          </div>
        </div>
        <div class="uploadImg" v-if="isSubmit">
          <img :src="uploadImg" :onerror="$global.srcImgUrl" class="img" alt="" />
          <span v-if="fileList.examId && fileList.tname !== '性格测试'" @click="goStudy(fileList)">进入考试评分</span>
          <span v-else-if="fileList.tname == '性格测试'" @click="goStudy1(fileList)">进入考试</span>
          <el-upload
            v-else
            class="upload-demo"
            name="uploadfile"
            :action="$global.uploadAction"
            :before-upload="beforeUpload"
            :on-success="
              (res) => {
                return upSuccess1(res);
              }
            "
            :on-error="upError"
            :show-file-list="false"
            multiple
            :limit="6"
          >
            <span>上传证明图片</span>
          </el-upload>

        </div>
      </div>
      <div class="imgInfo" v-if="fileList.examId">
        <div
          class="img"
          style="margin-bottom: 8px"
          v-if="fileList.datas && fileList.tname !== '性格测试'"
        >
          <div class="model">
            <div
              class="model-name"
              style="color: #0099ff; cursor: pointer"
              >
              考试分: {{ fileList.datas[0].file }}分
              </div
            >
            <!-- <span
              @click.stop="deleteImg(index, fileList.resume)"
              style="margin-left: 30px"
              ><i class="el-icon-delete" style="color: red"></i
            ></span> -->
          </div>
        </div>
      </div>
      <div class="imgInfo" v-else>
        <div
          class="img"
          style="margin-bottom: 8px"
          v-for="(item, index) in fileList.datas"
          :key="index"
        >
          <div class="model">
            <div
              class="model-name"
              @click.stop="modelImg(item, index)"
              style="color: #0099ff; cursor: pointer"
              >
              <!-- {{ getCaption(item.file) }} -->
              <img class="image-info" :src="item.file" :onerror="$global.srcImgUrl" alt="">
              </div
            >
            <span
              v-if="isSubmit"
              @click.stop="deleteImg(index, fileList.resume)"
              style="margin-left: 30px"
              ><i class="el-icon-delete" style="color: red; font-size:16px"></i
            ></span>
          </div>
        </div>
      </div>
    </div>
    <el-dialog title="预览" :visible.sync="previewVisible" width="50%">
      <div class="dialog-box">
        <img class="img" :src="previewImage" :onerror="$global.srcImgUrl" alt="" />
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "",
  data() {
    return {
      uploadImg: require("@/assets/icon/upload.png"),
      previewVisible: false,
      previewImage: "",
    };
  },
  props: {
    fileList: Object,
    isSubmit: Boolean
  },
  components: {},
  computed: {},
  mounted() {},
  methods: {
    // 上传文件判断
    beforeUpload(file) {
      const isJPG = file.type === "image/jpeg" || file.type === "image/png";
      const isLt2M = file.size / 1024 / 1024 < 5;

      if (!isJPG) {
        this.$message.error("上传图片只能是 .jpg .png 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 5MB!");
      }
      return isJPG && isLt2M;
    },
    // 上传失败
    upError() {
      this.$message({
        type: "error",
        message: "上传失败",
        showClose: true,
        offset: 80,
      });
    },
    goStudy(info){
      if(info.datas){
       this.$message({
          message: '您已考试过了,无法进行第二次考试',
          type: 'warning'
        })
      }else{
        this.$router.push({path:"/creditExamination", query: {
          id:info.examId,
          code: this.$route.query.idCard,
          value: this.$route.query.value,
          userId: this.$route.query.userId,
          indexId: info.id
          }})
      }
    },
    goStudy1(info){
      if(info.datas){
       this.$message({
          message: '您已考试过了,无法进行第二次考试',
          type: 'warning'
        })
      }else{
        this.$router.push({path:"/characterTestView", query: {
          id:info.examId,
          code: this.$route.query.idCard,
          value: this.$route.query.value,
          userId: this.$route.query.userId,
          indexId: info.id
          }})
      }
    },
    upSuccess1(res) {
      let obj = {
          file: res.msg
      }
      this.fileList.datas = []
      this.fileList.datas.push(obj);
      this.$emit("columnBtn", this.fileList);
    },
    deleteImg(index, label) {
      this.$emit("columnDel", this.fileList.datas[index]);
      this.fileList.datas.splice(index, 1);
    },
    // getCaption(obj) {
    //   var index = obj.lastIndexOf("\/");
    //   obj = obj.substring(index + 1, obj.length);
    //   return obj;
    // },
    modelImg(val) {
      this.previewVisible = true;
      this.previewImage = val.file;
    },
  },
};
</script>

<style scoped lang="scss">
.column {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  .leftInfo {
    .title {
      display: flex;
      align-items: center;
      h4 {
        font-size: 14px;
        font-family: "evaluation";
        margin: 7px 0 6px 0;
        margin-right: 7px;
      }
    }
  }
  .uploadImg {
    display: flex;
    align-items: center;
    width: 25%;
    justify-content: flex-end;
    font-size: 14px;
    color: #0099ff;
    .img {
      width: 19px;
      height: 22px;
    }
    span {
      margin-left: 5px;
    }
  }
}
.imgInfo {
  .model{
    display: flex;
    align-items: center;
  }
  .model-name {
    width: 200px;
    overflow: hidden; //隐藏
    white-space: nowrap; //不换行
    text-overflow: ellipsis; //超出部分省略号
  }
  .image-info{
    width: 60px;
    height: 60px;
  }
}
.dialog-box {
  width: 100%;
  .img {
    width: 100%;
  }
}
</style>
